import React from 'react';
import { Chip, Grid } from '@material-ui/core'
import ReactPlayer from 'react-player/lazy';
import { makeStyles } from '@material-ui/core/styles'
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {useSpring, animated} from 'react-spring';
import {BrowserView} from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    height: '100vh',
    paddingTop: 80,
    [theme.breakpoints.up('md')]: {
      paddingTop: 200,
    },
  },
  gridContainer: {
    height: '100vh',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: 'auto'
  },
  projectTitle: {
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightBold,
    color: 'white'
  },
  projectDesc: {
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightMedium,
  },
  chip: {
    margin: 3,
  },
  savingsTracker: {
  },
  googlePlayBtn: {
    width: 200,
    marginLeft: -15,
    marginTop: 10,
  },
  slide: {
    margin: 'auto',
    width: '50%',
  },
  slideShowImg: {
    width: '100%',
  },
  tetris: {
  },
  tetrisVideo: {
    margin: 'auto',
    width: '50%',
  },
  horizontalRule: {
    width: '75%',
    backgroundColor: 'white',
    margin: 50
  },
  bottomEmptySpace: {
    display: 'block',
    height: '20vh',
  },
  linkColor: {
    color: 'white',
    textDecoration: 'underline'
  },
}))

function Slider({data}) {
  const classes = useStyles()
  return (
    <Fade
      easing="ease"
      className={classes.slide}
      arrows={false}
      duration={3000}>
      {
        data.map((value, index) => (
          <img src={value.url} className={classes.slideShowImg} key={index} />
        ))
      }
    </Fade>
  )
}

function SavingsTracker() {
  const classes = useStyles()

  const skills = [
    {
      link: "https://kotlinlang.org/",
      name: "Kotlin",
    },
    {
      link: "https://developer.android.com/jetpack/androidx/releases/room",
      name: "Room",
    },
    {
      link: "https://developer.android.com/guide/navigation",
      name: "Navigation",
    },
    {
      link: "https://firebase.google.com/",
      name: "Firebase (Firestore, authentication, analytics)",
    },
    {
      link: "https://github.com/ReactiveX/RxAndroid",
      name: "RxAndroid",
    },
    {
      link: "https://dagger.dev/dev-guide/android.html",
      name: "Dagger",
    },
    {
      link: "https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93presenter#:~:text=Model%E2%80%93view%E2%80%93presenter%20(MVP,is%20pushed%20to%20the%20presenter.",
      name: "MVP architectural",
    }
  ]

  const imagesList = [
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_1.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_2.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_3.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_4.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_5.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_6.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_7.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_8.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_9.jpg" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/savingstracker/img_10.jpg" },
  ];

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.savingsTracker}
    >
      <Grid item xs={10} md={4}>
        <Slider data={imagesList} />
      </Grid>
      <Grid item xs={10} md={4}>
        <h1 className={classes.projectTitle}>Savings Tracker</h1>
        <p className={classes.projectDesc}>A native Android application that can help people track their savings. 
        I developed Savings Tracker because I was saving to buy a house, and I wanted to learn Kotlin and some of 
        the new Android Jetpack components like Room, Navigation, etc.</p>
        <p className={classes.projectDesc}>Built with:</p>
        <div>
          {
            skills.map((value, index) => (
              <Chip
                key={index}
                label={value.name}
                className={classes.chip}
                variant="outlined"
                component="a"
                href={value.link}
                clickable />
            ))
          }
        </div>
        <a href='https://play.google.com/store/apps/details?id=com.longnguyen.savingstracker&hl=en_US&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          target="_blank" rel="noreferrer">
          <img className={classes.googlePlayBtn} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
        </a>
      </Grid>
    </Grid>
  )
}

function SpeechX() {
  const classes = useStyles()

  const skills = [
    {
      link: "https://www.javascript.com/",
      name: "JavaScript",
    },
    {
      link: "https://reactnative.dev/",
      name: "React Native",
    },
    {
      link: "https://azure.microsoft.com/en-us/services/cognitive-services/",
      name: "Azure Cognitive Services (Text to Speech)",
    },
  ]

  const imagesList = [
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/speechx/screen_1.png" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/speechx/screen_2.png" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/speechx/screen_3.png" },
    { url: "https://d2rc24qtekh6p9.cloudfront.net/images/speechx/screen_4.png" },
  ];

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.savingsTracker}
    >
      <Grid item xs={10} md={4}>
        <Slider data={imagesList} />
      </Grid>
      <Grid item xs={10} md={4}>
        <h1 className={classes.projectTitle}>SpeechX - Text to Speech</h1>
        <p className={classes.projectDesc}>Model X, SpaceX? Whatever, now we have SpeechX (TBH, naming things is hard). 
        The main feature of the application is to convert text to speech. I developed SpeechX for my personal use, and 
        it might be helpful for other people who want to speak another language. The application is developed with React 
        Native and the Speech SDK from Azure.</p>
        <p className={classes.projectDesc}>Built with:</p>
        <div>
          {
            skills.map((value, index) => (
              <Chip
                key={index}
                label={value.name}
                className={classes.chip}
                variant="outlined"
                component="a"
                href={value.link}
                clickable />
            ))
          }
        </div>
        <a href='https://play.google.com/store/apps/details?id=com.longnguyen.speechx&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
          target="_blank" rel="noreferrer">
          <img className={classes.googlePlayBtn} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
        </a>      
      </Grid>
    </Grid>
  )
}

function Tetris() {
  const classes = useStyles()

  const skills = [
    {
      link: "https://en.wikipedia.org/wiki/Java_(programming_language)",
      name: "Java",
    },
    {
      link: "https://en.wikipedia.org/wiki/Swing_(Java)",
      name: "Java Swing",
    }
  ]

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.tetris}
    >
      <Grid item xs={10} md={4}>
        <TetrisVideo />
      </Grid>
      <Grid item xs={10} md={4}>
        <h1 className={classes.projectTitle}>Tetris</h1>
        <p className={classes.projectDesc}>
          A course project from <a href="https://myplan.uw.edu/course/#/courses/TCSS305" target="_blank" rel="noreferrer" className={classes.linkColor}>TCSS 305 Programming Practicum</a>
          . The source code is available on <a href="https://github.com/ln206/Tetris" target="_blank" rel="noreferrer" className={classes.linkColor}>GitHub</a>.</p>
        <p className={classes.projectDesc}>Built with:</p>
        <div>
          {
            skills.map((value, index) => (
              <Chip
                key={index}
                label={value.name}
                className={classes.chip}
                variant="outlined"
                component="a"
                href={value.link}
                clickable />
            ))
          }
        </div>
      </Grid>

    </Grid>
  )
}

function TetrisVideo() {
  const classes = useStyles()

  return (
    <ReactPlayer
      url='https://d2rc24qtekh6p9.cloudfront.net/videos/tetris.mp4'
      playing={BrowserView ? true : false}
      loop={true}
      muted={true}
      controls={true}
      width={300}
      className={classes.tetrisVideo} />
  )
}

export default function PersonalProjects() {
  const classes = useStyles()
  const props = useSpring({opacity: 1, from: {opacity: 0}, config: { duration: 1500 }})

  return (
    <animated.div style={props} className={classes.root}>
      <Grid
        className={classes.gridContainer}
        container
        direction="row"
        justify="center"
        alignItems="center">
        <SavingsTracker />
        <hr className={classes.horizontalRule} />
        <SpeechX />
        <hr className={classes.horizontalRule} />
        <Tetris />
        <Grid item xs={12}>
          <div className={classes.bottomEmptySpace} />
        </Grid>
      </Grid>
    </animated.div>
  )
}