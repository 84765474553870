import React from 'react';
import { useRef, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CloudPdfViewer from '@openbook/cloudpdf-viewer';
import { useSpring, animated } from 'react-spring'

const useStyles = makeStyles((theme) => (
  {
    root: {
      backgroundColor: theme.palette.background.default
    },
    viewer: {
      marginTop: 60,
      height: '94vh',
    }
  }
))

export default function Resume() {
  const classes = useStyles();
  const viewer = useRef(null);
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 1500 } })

  useEffect(() => {
    CloudPdfViewer(
      {
        documentId: "eafd66c1-88ba-4824-98cd-2836470e78d5",
        darkMode: true,
        themeColor: '#263238'
      },
      viewer.current
    ).then(() => { });
  }, []);

  return (
    <animated.div style={props} className={classes.root}>
      <div className={classes.viewer} ref={viewer}></div>
    </animated.div>
  );
}