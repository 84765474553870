import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from "react-router-dom";
import {
  AppBar, Toolbar, Button, IconButton,
  Hidden, Drawer, List, ListItem,
  ListItemText, Grid
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  drawerPaper: {
    width: drawerWidth
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  appBarItem: {
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    marginLeft: 15,
    marginRight: 15,
  },
  drawerItem: {
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightBold,
    color: 'white'
  },
  drawerItemActive: {
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightBold,
    color: 'grey'
  },
  link: {
    color: 'white',
    textDecoration: 'none'
  },
  linkActive: {
    color: 'grey',
    textDecoration: 'none'
  }
}))

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function NavBar(props) {
  let location = useLocation();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [navItems, setNavItems] = useState([])

  const navItemsInitial = [
    { text: 'About Me', path: '/', active: true },
    { text: 'Personal Projects', path: '/personalprojects', active: false },
    { text: 'Resume', path: '/resume', active: false }]

  useEffect(() => {
    const tempList = []
    for (var i = 0; i < navItemsInitial.length; i++) {
      if (location.pathname !== navItemsInitial[i].path) {
        navItemsInitial[i].active = false
      } else {
        navItemsInitial[i].active = true
      }
      tempList.push(navItemsInitial[i])
    }
    setNavItems(tempList)
  }, [location])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onDrawerItemClick = () => {
    handleDrawerToggle()
  }

  const drawer = (
    <div>
      <List>
        {navItems.map((value, index) => (
          <Link to={value.path} className={classes.link} key={index}>
            <ListItem button onClick={onDrawerItemClick}>
              <ListItemText
                disableTypography
                className={value.active ? classes.drawerItemActive : classes.drawerItem}
                primary={value.text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          className={classes.appBar}
          elevation={0}>
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                onClick={handleDrawerToggle}
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu">
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center">
                {
                  navItems.map((value, index) => (
                    <Link
                      key={index}
                      to={value.path}
                      className={value.active ? classes.linkActive : classes.link}>
                      <Grid item>
                        <Button
                          color="inherit"
                          className={classes.appBarItem}
                        >
                          {value.text}
                        </Button>
                      </Grid>
                    </Link>

                  ))
                }
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>
      </ElevationScroll>


      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <IconButton
            onClick={handleDrawerToggle}
            className={classes.closeMenuButton}>
            <CloseIcon />
          </IconButton>
          {drawer}
        </Drawer>
      </Hidden>
    </>
  )
}