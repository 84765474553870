import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Chip, Button, Link, Hidden } from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';
import CakeIcon from '@material-ui/icons/Cake';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { useSpring, animated } from 'react-spring'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    marginBottom: 40
  },
  introContainer: {
    marginTop: 80,
    [theme.breakpoints.up('md')]: {
      marginTop: 200,
    },
    paddingLeft: '10%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0%',
    },
    paddingRight: '5%',
  },
  introTitle: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px',
    color: 'white',
    paddingRight: 20,
    paddingLeft: 20,
  },
  introDescription: {
    padding: 0,
    margin: 0,
    lineHeight: '25px',
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '17px',
    color: 'white',
    paddingRight: 20,
    paddingLeft: 20,
  },
  introSkill: {
    padding: 0,
    margin: 0,
    marginTop: 15,
    lineHeight: '40px',
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '20px',
    color: 'white',
    paddingRight: 20,
    paddingLeft: 20,
  },
  timelineTitle: {
    marginTop: 190,
    [theme.breakpoints.down('sm')]: {
      marginTop: 50,
    },
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px',
    color: 'white',
    paddingRight: 20,
    paddingLeft: 20,
  },
  verticalTimeLine: {
    paddingRight: '20%',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5%',
    },
  },
  timelineDate: {
    color: 'grey',
    fontFamily: theme.defaultFont,
  },
  timelineText: {
    color: 'black',
    fontFamily: theme.defaultFont,
  },
  chipContainer: {
    marginLeft: 15,
  },
  chip: {
    margin: 3,
  },
  contacts: {
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
    fontFamily: theme.defaultFont,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: 20,
    paddingLeft: 20,
  },
  contactsBtn: {
    color: 'white',
    marginLeft: 20,
  },
  contactsLink: {
    color: 'white'
  },
  horizontalRule: {
    width: '75%',
    backgroundColor: 'white',
    margin: 'auto',
    marginTop: 40
  },
  galleryLink: {
    color: 'white',
    textDecoration: 'underline'
  }
}))

function Contact() {
  const classes = useStyles()

  return (
    <div>
      <h1 className={classes.contacts}>Contact:</h1>
      <Button
        className={classes.contactsBtn}
        variant="outlined"
        startIcon={<EmailIcon />}
      >
        <Link href="mailto:lnguyen206@protonmail.com" className={classes.contactsLink}>Email</Link>
      </Button>
      <Button
        className={classes.contactsBtn}
        variant="outlined"
        startIcon={<LinkedInIcon />}
      >
        <Link href="https://www.linkedin.com/in/ln206/" className={classes.contactsLink}>LinkedIn</Link>
      </Button>
    </div>
  )
}

function AboutMe() {
  const classes = useStyles()
  const props = useSpring({ opacity: 1, from: { opacity: 0 }, config: { duration: 1500 } })

  const skills = [
    "Java", "Kotlin", "JavaScript", "HTML", "CSS",
    "Android", "Tizen", "React Native", "React.js", "Node.js",
    "Git", "Firebase", "AWS (CloudFront, S3, Route 53)"
  ]

  const tools = [
    "Android Studio", "Visual Studio Code", "Confluence", "Jira", "Trello", "Slack",
    "Microsoft Teams", "Sourcetree", "Postman", "Microsoft Office"
  ]

  const timelineItems = [
    {
      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
      icon: <SchoolIcon />,
      year: '2016',
      text: 'Graduated from University of Washington - Tacoma with a B.S. in Computer Science and a GPA of 3.69 out of 4.'
    },
    {
      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
      icon: <WorkIcon />,
      year: '2016',
      text: 'Joined TecAce Software as a software developer.'
    },
    {
      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
      icon: <CakeIcon />,
      year: '2017',
      text: 'Won the best member award at TecAce Software.'
    },
    {
      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
      icon: <CakeIcon />,
      year: '2019',
      text: 'Promoted to developer lead position at TecAce Software.'
    },
    {
      iconStyle: { background: 'rgb(33, 150, 243)', color: '#fff' },
      icon: <CakeIcon />,
      year: '2020',
      text: 'Won the best leadership award at TecAce Software.'
    }
  ]

  return (
    <animated.div style={props}>
      <Grid
        className={classes.root}
        container
        justify="center"
        direction="row">
        <Grid
          xs={12}
          md={6}
          item
          className={classes.introContainer}>
          <h3 className={classes.introTitle}>Hi, I&apos;m Long.</h3>
          <br />
          <p className={classes.introDescription}>I&apos;m an Android Developer based in Seattle, WA. At work, I create retail applications for Samsung&apos;s flagship phones and tablets that are being displayed at many retail stores worldwide.
          Outside of work, I love to learn new things that can help me become a better developer.</p>
          <p className={classes.introSkill}>Skills:</p>
          <div className={classes.chipContainer}>
            {
              skills.map((value, idx) => (
                <Chip
                  label={value}
                  key={idx}
                  className={classes.chip}
                  variant="outlined"
                  clickable
                />
              ))
            }
          </div>
          <p className={classes.introSkill}>Tools:</p>
          <div className={classes.chipContainer}>
            {
              tools.map((value, idx) => (
                <Chip
                  label={value}
                  key={idx}
                  className={classes.chip}
                  variant="outlined"
                  clickable
                />
              ))
            }
          </div>
          <Hidden smDown>
            <Contact />
          </Hidden>

          <Hidden mdUp>
            <hr className={classes.horizontalRule} />
          </Hidden>

        </Grid>
        <Grid
          container
          xs={12}
          md={6}
          item>
          <Grid item xs={12}>
            <p className={classes.timelineTitle}>Major milestones</p>
          </Grid>
          <Grid item xs={12}>
            <VerticalTimeline
              layout={'1-column-left'}
              className={classes.verticalTimeLine}
            >
              {
                timelineItems.map((value, index) => {
                  return (
                    <VerticalTimelineElement
                      key={index}
                      iconStyle={value.iconStyle}
                      icon={value.icon}>
                      <h3 className={classes.timelineDate}>{value.year}</h3>
                      <p className={classes.timelineText}>{value.text}</p>
                    </VerticalTimelineElement>
                  )
                })
              }
            </VerticalTimeline>
          </Grid>

          <Hidden mdUp>
            <hr className={classes.horizontalRule} />
          </Hidden>

          <Hidden mdUp>
            <Contact />
          </Hidden>
        </Grid>
      </Grid>
    </animated.div>
  )
}

export default AboutMe;