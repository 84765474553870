import React from 'react';
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles'
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom"
import ScrollToTop from './components/ScrollToTop'
import NavBar from './components/NavBar'
import AboutMe from './components/AboutMe'
import PersonalProjects from './components/PersonalProjects'
import Resume from './components/Resume'
import './App.css'

const theme = createMuiTheme({
  defaultFont: "Raleway",
  palette: {
    type: 'dark',
    typography: {
      "fontFamily": `"Roboto", "Helvetica", "Arial", sans-serif, "Raleway"`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 600,
      "fontWeightStrong": 800,
    },
    primary: {
      light: '#8eacbb',
      main: '#607d8b',
      dark: '#34515e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fdffff',
      main: '#cae5f4',
      dark: '#99b3c1',
      contrastText: '#fff',
    },
    background: {
      default: "#263238"
    },
  }
})

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Router>
          <ScrollToTop />
          <NavBar />
          <Switch>
            <Route exact path="/">
              <AboutMe />
            </Route>
            <Route path="/personalprojects">
              <PersonalProjects />
            </Route>
            <Route path="/resume">
              <Resume />
            </Route>
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
